import React from 'react'
import Layout from '../components/layout'
import SearchInput from '../components/SearchInput'
import Seo from "../components/seo"

const UnitedStatesResources = () => {
    return (
        <Layout>
            <Seo title="United States Resources" />

            <h1 className="title">United States Resources</h1>

            <SearchInput />

            <table>
                <tbody>
                    {/* HEADING */}

                    <tr className="thead title">
                        <td colSpan="2">US Patents Searching</td>
                    </tr>

                    <tr>
                        <td>Public patent search</td>
                        <td><a target="_blank" rel="noreferrer" href="https://ppubs.uspto.gov/pubwebapp/static/pages/landing.html">https://ppubs.uspto.gov/pubwebapp/static/pages/landing.html</a></td>
                    </tr>

                    <tr>
                        <td>Patent application full text and image database</td>
                        <td><a target="_blank" rel="noreferrer" href="https://appft.uspto.gov/netahtml/PTO/search-bool.html">https://appft.uspto.gov/netahtml/PTO/search-bool.html</a></td>
                    </tr>

                    <tr>
                        <td>Patent assignment search</td>
                        <td><a target="_blank" rel="noreferrer" href="https://assignment.uspto.gov/patent/index.html#/patent/search">https://assignment.uspto.gov/patent/index.html#/patent/search</a></td>
                    </tr>

                    <tr>
                        <td>TESS (trademark electronic search system)</td>
                        <td><a target="_blank" rel="noreferrer" href="https://tmsearch.uspto.gov/bin/gate.exe?f=login&p_lang=english&p_d=trmk">https://tmsearch.uspto.gov/bin/gate.exe?f=login&p_lang=english&p_d=trmk</a></td>
                    </tr>

                    <tr>
                        <td>USPTO (Patent Centre) – Individual prosecution histories</td>
                        <td><a target="_blank" rel="noreferrer" href="https://patentcenter.uspto.gov/">https://patentcenter.uspto.gov/</a></td>
                    </tr>

                    <tr>
                        <td>USTP – PTE consolidated lists (granted/applications filed in last 5 years)</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.uspto.gov/patents/laws/patent-term-extension/patent-terms-extended-under-35-usc-156">https://www.uspto.gov/patents/laws/patent-term-extension/patent-terms-extended-under-35-usc-156</a></td>
                    </tr>

                    <tr className="thead title">
                        <td colSpan="2">US Case/Office</td>
                    </tr>

                    <tr>
                        <td>US Court of Appeals for the Federal Circuit case law search</td>
                        <td><a target="_blank" rel="noreferrer" href="https://cafc.uscourts.gov/home/case-information/">https://cafc.uscourts.gov/home/case-information/</a></td>
                    </tr>

                    <tr>
                        <td>USPTO database of Federal Circuit patent and trade mark decisions (FOIA documents)</td>
                        <td><a target="_blank" rel="noreferrer" href="https://foiadocuments.uspto.gov/federal/">https://foiadocuments.uspto.gov/federal/</a></td>
                    </tr>

                    <tr>
                        <td>Patent Trial and Appeal Board case law</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.uspto.gov/patents/ptab/ptab-it-systems">https://www.uspto.gov/patents/ptab/ptab-it-systems</a></td>
                    </tr>

                    <tr>
                        <td>Supreme Court case law search</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.supremecourt.gov/search.aspx?Search=case+law">https://www.supremecourt.gov/search.aspx?Search=case+law</a></td>
                    </tr>

                    <tr>
                        <td>Justia (case law search) (including District Courts)</td>
                        <td><a target="_blank" rel="noreferrer" href="https://law.justia.com/cases/">https://law.justia.com/cases/</a></td>
                    </tr>

                    <tr>
                        <td>Leagle (case law search) (including District Courts)</td>
                        <td><a target="_blank" rel="noreferrer" href="https://portal.unifiedpatents.com/">https://portal.unifiedpatents.com/</a></td>
                    </tr>

                    <tr>
                        <td>US Portal (case search) </td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.leagle.com/leaglesearch">https://www.leagle.com/leaglesearch</a></td>
                    </tr>

                    <tr>
                        <td>US PTAB/IPR case search</td>
                        <td><a target="_blank" rel="noreferrer" href="https://ptab.uspto.gov/#/login">https://ptab.uspto.gov/#/login</a></td>
                    </tr>

                    <tr className="thead title">
                        <td colSpan="2">US Pharma/Product Searches</td>
                    </tr>

                    <tr>
                        <td>US Abbreviated New Drug Application (ANDA) Paragraph IV certifications</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.fda.gov/drugs/abbreviated-new-drug-application-anda/patent-certifications-and-suitability-petitions">https://www.fda.gov/drugs/abbreviated-new-drug-application-anda/patent-certifications-and-suitability-petitions</a></td>
                    </tr>

                    <tr>
                        <td>FDA paediatric exclusivity grants</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.fda.gov/drugs/development-resources/pediatric-exclusivity-granted">https://www.fda.gov/drugs/development-resources/pediatric-exclusivity-granted</a></td>
                    </tr>

                    <tr>
                        <td>FDA paediatric exclusivity determinations and written requests</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.fda.gov/drugs/development-resources/list-determinations-including-written-request">https://www.fda.gov/drugs/development-resources/list-determinations-including-written-request</a></td>
                    </tr>

                    <tr>
                        <td>FDA search orphan drug designations and approvals</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.accessdata.fda.gov/scripts/opdlisting/oopd/">https://www.accessdata.fda.gov/scripts/opdlisting/oopd/</a></td>
                    </tr> 

                    <tr>
                        <td>FDA approved drugs</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.accessdata.fda.gov/scripts/cder/daf/">https://www.accessdata.fda.gov/scripts/cder/daf/</a></td>
                    </tr>

                    <tr>
                        <td>FDA Orange Book (approved small molecule drugs and linked patents)</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.accessdata.fda.gov/scripts/cder/ob/index.cfm">https://www.accessdata.fda.gov/scripts/cder/ob/index.cfm</a></td>
                    </tr>

                    <tr>
                        <td>FDA Purple Book (approved large molecule drugs and linked patents)</td>
                        <td><a target="_blank" rel="noreferrer" href="https://purplebooksearch.fda.gov/">https://purplebooksearch.fda.gov/</a></td>
                    </tr>

                    {/* HEADING */}

                    <tr className="thead title">
                        <td colSpan="2">US Code, Regs and Manual</td>
                    </tr>

                    <tr>
                        <td>US Code</td>
                        <td><a target="_blank" rel="noreferrer" href="http://uscode.house.gov/">http://uscode.house.gov/</a></td>
                    </tr>

                    <tr>
                        <td>US Regulations (public access to regulatory materials)</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.regulations.gov/">https://www.regulations.gov/</a></td>
                    </tr>

                    <tr>
                        <td>US Manual of Patent Examining Procedure</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.uspto.gov/web/offices/pac/mpep/index.html">https://www.uspto.gov/web/offices/pac/mpep/index.html</a></td>
                    </tr>

                    <tr>
                        <td>Global Clinical trials</td>
                        <td><a target="_blank" rel="noreferrer" href="https://clinicaltrials.gov/">https://clinicaltrials.gov/</a></td>
                    </tr>

                    {/* HEADING */}

                    <tr className="thead title">
                        <td colSpan="2">SEC filings</td>
                    </tr>

                    <tr>
                        <td>US Pharma/Product searches</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.sec.gov/edgar/search/#">https://www.sec.gov/edgar/search/#</a></td>
                    </tr>
                </tbody>
            </table>
        </Layout>
    )
}

export default UnitedStatesResources